<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage" tooltip-effect="light">
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column prop="clueName" label="线索来源" />
      <el-table-column prop="tag" label="标签" />
      <el-table-column prop="crmType" label="推送渠道" />
      <el-table-column prop="syncStatus" label="推送状态" width="100" />
      <el-table-column prop="syncMsg" label="推送结果">
        <template #default="{row}">{{ row.syncMsg }}</template>
      </el-table-column>
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="phoneNumber" label="电话" >
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱" />
      <el-table-column prop="companyName" label="公司" />
      <el-table-column prop="descri" label="描述" />
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
// import { ElMessage } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
// import { formatTime } from "../../common/utils";
import useClue from "./useClue";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const { saleClueTagEnums, saleClueSourceEnums } = useClue()
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      saleClueEnums: {},
      params: [
        // {
        //   key: "clueName",
        //   value: "",
        //   label: "线索来源",
        // },
        // {
        //   key: "tag",
        //   value: "",
        //   label: "标签",
        // },
        {
          type: "Select",
          key: "clueName",
          value: "",
          label: "线索来源",
          options: {
            data: saleClueSourceEnums,
            label: "label",
            value: "value"
          },
          attrs: {
            multiple: true,
            filterable: true,
            collapseTags: true
          }
        },
        {
          type: "Select",
          key: "tag",
          value: "",
          label: "标签",
          options: {
            data: saleClueTagEnums,
            label: "label",
            value: "value",
          },
          attrs: {
            multiple: true,
            filterable: true,
            collapseTags: true
          }
        },
        {
          key: "userName",
          value: "",
          label: "姓名",
        },
        {
          key: "phoneNumber",
          value: "",
          label: "电话号码",
        },
        {
          type: "Select",
          key: "syncStatus",
          value: "",
          label: "推送状态",
          options: {
            data: [
              { label: 'Y', value: 'Y' },
              { label: 'N', value: 'N' },
            ],
            label: "label",
            value: "value"
          }
        },
        {
          type: "Date",
          key: "createRange",
          value: [],
          label: "创建时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "x",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
      ],
    })

    const getData = async () => {
      try {
        
        const res = await request('getClueList', {
          ...state.query,
          pageNum: state.page,
          pageSize: state.size,
        });
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data;
      if (data.createRange && data.createRange.length>0) {
        state.query.startTime = data.createRange[0]
        state.query.endTime = data.createRange[1]
      } else {
        state.query.startTime = ''
        state.query.endTime = ''
      }
      getData()
    };
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    };

    getData();
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage,
    };
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>