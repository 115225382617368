import { ref, onMounted } from 'vue'
// import { request } from "@/utils/request";
import { reqSaleClueEnums } from "@/api/robot"
export default function () {
  const saleClueTagEnums = ref([])
  const saleClueSourceEnums = ref([])

  const getSaleClueEnums = async () => {
    try {
      const res = await reqSaleClueEnums()
      saleClueSourceEnums.value = res.saleClueSourceEnums
      saleClueTagEnums.value = res.saleClueTagEnums
    } catch (error) {
      console.error(error)
    }
  }
  onMounted(() => {
    getSaleClueEnums()
  })

  return {
    saleClueTagEnums,
    saleClueSourceEnums
  }
}